
import { Component, Vue } from 'vue-property-decorator';
import LogIn from './../components/LogIn.vue';

@Component({
  components: { 
    LogIn
   },
})
export default class HomeView extends Vue {}
